<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="900" persistent>
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{`${edit?'Update':'Add'}`}} request for leave
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select label="Categories" :items="leave_categories"
                                      item-text="title"
                                      item-value="id" :error="$v.leave_request.leave_category_id.$error"
                                      @change="checkCategoryChange"
                                      :loading="categoryLoading"
                                      v-model="leave_request.leave_category_id" outlined dense>
                            </v-select>
                            <span class="text-danger" v-if="$v.leave_request.leave_category_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field label="Title" v-model="leave_request.title" outlined dense :error="$v.leave_request.title.$error">
                            </v-text-field>
                            <span class="text-danger" v-if="$v.leave_request.title.$error">This information is required</span>
                        </v-col>
                        <v-col cols="12">
                            <label for="description">Description</label>
                            <ckeditor id="description" v-model="leave_request.description"
                                      :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.leave_request.description.$error">This information is required</span>

                        </v-col>
                        <v-col cols="12" sm="6"
                                md="4">
                            <v-menu v-model="leave_from"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="leave_request.leave_start_date"
                                            label="Leave from"
                                            append-icon="mdi-calendar"
                                            readonly
                                            outlined :error="$v.leave_request.leave_start_date.$error"
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                       :min="today"
                                        v-model="leave_request.leave_start_date"
                                        @input="leave_from = false"
                                ></v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="$v.leave_request.leave_start_date.$error">This information is required</span>
                        </v-col>
                        <v-col cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="leave_to"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="leave_request.leave_end_date"
                                            label="Leave end"
                                            append-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        :min="today"
                                        v-model="leave_request.leave_end_date"
                                        @input="leave_to = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox v-model="leave_request.is_half" label="Half Day"></v-checkbox>
                        </v-col>
                        <v-col md="4" cols="12">
                            <v-file-input
                                    label="Attachment 1"
                                    outlined
                                    v-model="leave_request.attachment_1"
                                    dense
                            ></v-file-input>
                        </v-col>
                        <v-col md="4" cols="12">
                            <v-file-input
                                    label="Attachment 1"
                                    outlined
                                    v-model="leave_request.attachment_2"
                                    dense
                            ></v-file-input>
                        </v-col>
                        <v-col md="4" cols="12">
                            <v-file-input
                                    label="Attachment 1"
                                    outlined
                                    v-model="leave_request.attachment_3"
                                    dense
                            ></v-file-input>
                        </v-col>
<!--                        <v-col cols="3">-->
<!--                            <v-switch v-model="leave_request.is_active" label="Active"></v-switch>-->
<!--                        </v-col>-->
<!--                        <v-col cols="3">-->
<!--                            <v-switch v-model="leave_request.auto_approval" label="Auto Approval"></v-switch>-->
<!--                        </v-col>-->
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="resetForm" class="text-gray btn btn-standard  cancel-btn">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                                       class="text-white ml-2 btn btn-primary ">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import LeaveCategoryService from "@/core/services/leave/leave-category/LeaveCategoryService";
    const leaveCategoryService = new LeaveCategoryService();
    import LeaveRequestService from "@/core/services/leave/leave-request/LeaveRequestService";
    const leaveRequestService = new LeaveRequestService();

    export default {
        name: 'create-leave-category',
        validations: {
            leave_request: {
                title: {required},
                leave_category_id: {required},
                description: {required},
                leave_start_date: {required},

            }
        },
        data() {
            return {
                academic_class: [],
                leave_categories: [],
                dialog: false,
                edit: false,
                selectedCategory: null,
                leave_from: false,
                today: new Date().toISOString().substr(0,10),
                leave_to: false,
                categoryLoading: false,
                isBusy: false,
                search: {
                    active: "active",
                    is_parent: 1,
                },
                applicable_for: [
                    {
                        name: 'Student',
                        value: 'student'
                    },
                    {
                        name: 'Teachers',
                        value: 'teacher'
                    }, {
                        name: 'Staff',
                        value: 'staff'
                    },
                    {
                        name: 'All',
                        value: 'all'
                    }
                ],
                editorConfig: {
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
                leave_request: {
                    title: null,
                    leave_category_id: null,
                    description: null,
                    leave_start_date: null,
                    leave_end_date: null,
                    attachment_1: null,
                    attachment_2: null,
                    attachment_3: null,
                    is_half: false,
                    is_active: true,
                  requested_by:'student',
                },
            };
        },
        mounted() {

        },
        methods: {
            checkCategoryChange(){
                 this.selectedCategory = this.leave_categories.filter(leave => {
                    return leave.id === this.leave_request.leave_category_id;
                })
            },
            openDialog(){
              this.dialog = true;
            },
            closeDialog(){
              this.dialog = false;
            },
            createNewLeaveRequest(){
              this.edit=false;
              this.openDialog();
              this.__getCategory();
            },
            editLeaveRequest(id){
              if (id) {
                this.openDialog();
                this.__getCategory();
                this.edit = true;
                this.__getSingle(id)
              }
            },


            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
          __getSingle(id) {

              leaveRequestService.show(id).then(response => {
                    this.leave_request = response.data.leave_request
                })
            },
            __getCategory() {
                this.categoryLoading = true;
                leaveCategoryService.paginate().then(response => {
                    this.leave_categories = response.data.leave_category
                }).catch(error=>{
                    console.log(error)
                }).finally(()=>{
                    this.categoryLoading = false;
                })
            },

            generateFd() {
                // console.log(this.leave_request)
                let fd = new FormData();
                for (let key in this.leave_request) {
                    if (key == "is_half" && this.leave_request["is_half"]) {
                        fd.append("is_half", this.leave_request ? 1 : 0);
                    } else {
                        if (this.leave_request[key] != null) {
                            fd.append(key, this.leave_request[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {

                this.$v.leave_request.$touch();
                if (this.$v.leave_request.$error) {
                    setTimeout(() => {
                        this.$v.leave_request.$reset();
                    }, 3000);
                } else {
                        let fd = this.generateFd();
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                leaveRequestService
                    .update(this.leave_request.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Updated successfully");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            __create(fd) {
                this.isBusy = true;
                leaveRequestService
                    .store(fd)
                    .then((response) => {
                        // console.log(response)
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Created successfully");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.leave_request.$reset();
                this.edit = false;
                this.leave_request = {
                    title: null,
                    leave_category_id: null,
                    description: null,
                    leave_start_date: null,
                    leave_end_date: null,
                    attachment_1: null,
                    attachment_2: null,
                    attachment_3: null,
                    is_half: false,
                    is_active: true,
                  requested_by:'student',
                };
                this.closeDialog();
                this.$emit("refresh_leave_request");
            }
        }
    };
</script>
