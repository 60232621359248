<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Manage leaves
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Leaves
                </div>
              </div>
              <div class="breadcrumb-right">

                <v-btn  @click="__add()" class="mt-4 btn btn-primary" style="color: #fff">
                  <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                  Add leave
                </v-btn>&nbsp;
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th>Request title</th>
                  <th class="px-2">Info</th>
                  <th class="px-2">Status</th>

                </tr>
                </thead>
                <tbody>
                  <tr v-show="leave_requests.length > 0" :key="index" v-for="(leave_category, index) of leave_requests">
                    <td class="px-3">
                      <a @click="__edit(leave_category.id)"  class="mr-2" >
                        {{leave_category.title }}
                      </a>



                    </td>
                    <td>
                      <div class="leave-date">
                        <span class="font-weight-bold">
                          Leave from : &nbsp;
                        </span>
                          <span class="start-date" v-if="leave_category.leave_start_date">
                            {{ leave_category.leave_start_date | moment("Do") }}
                          </span>
                          <span class="dash" v-if="leave_category.leave_start_date && leave_category.leave_end_date">
                            &mdash;
                          </span>
                          <span class="end-date" v-if="leave_category.leave_end_date">
                              {{ leave_category.leave_end_date | moment("Do MMMM YYYY") }}
                        </span>
                      </div>
                      <div class="text-secondary">
                        <span class="font-weight-bold">
                          Category : &nbsp;
                        </span>
                        <span v-if="leave_category.category">
                          <span class="badge badge-primary ml-2 text-capitalize">
                            {{leave_category.category}}
                          </span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <template v-if="leave_category.status">
                        <span  :class="leaveStatus(leave_category.status)"
                               class="badge text-capitalize">
                          {{leave_category.status ? leave_category.status : 'Pending'}}
                        </span>
                      </template>

                    </td>

                  </tr>
                  <tr v-if="leave_requests.length == 0">
                    <td colspan="5" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="leave_requests.length > 0"
                  class="pull-right mt-7"
                  :per-page="perPage"
                  :total-rows="total"
                  @input="__get"
                  first-number
                  last-number
                  v-model="page"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="leave_category" @refresh_leave_request="__get"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import LeaveRequestService from "@/core/services/leave/leave-request/LeaveRequestService";

const leaveRequest = new LeaveRequestService();
export default {
  components: {CreateAndUpdate},
  data(){
    return{
      leave_requests: [],
      page: null,
      loading: false,
      perPage: null,
      total: null,
    }
  },
  methods:{
    __get() {
      this.loading = true;
      leaveRequest
          .paginate(this.search)
          .then(response => {
            this.leave_requests = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch(error => {
            // console.log(error);
          }).finally(() => {
        this.loading = false;

      });
    },
    __add() {
      this.$refs["leave_category"].createNewLeaveRequest();
    },
    __edit(id) {

      this.$refs["leave_category"].editLeaveRequest(id);
    },
    leaveStatus(status) {
      if (status) {
        switch (status) {
          case 'pending':
            return  'badge-warning';
            break;
          case 'approved':
            return  'badge-success';
            break;
          case 'declined':
            return  'badge-danger';
            break;

        }
      } else {
        return 'badge-warning'
      }
    },
  },
  mounted() {
    this.__get();
  }
}
</script>
<style lang="css">
.leave-date {
  display: flex;
  align-items: center;
  gap: 4px;
}

.start-date,
.end-date {
  font-weight: bold;
}

.dash {
  font-weight: bold;
  color: #888;
}
.badge-danger {
  color: #ffffff !important;
  background-color: #F64E60 !important;
}
</style>
